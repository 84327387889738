/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/profile-pic.png"
        width={50}ƒ
        height={50}
        quality={95}
        alt="Profile picture"
      />
      {author?.name && (
         <p>
        I'm an Engineering Manager and once and future front-end focused full-stack engineer.
        I'm hoping to use this space to share and talk through challenges (and wins!) in
        engineering management, especially developing your engineers' (and
        your own!) career growth! (All opinions my own)
        </p>        
      )}
    </div>
  )
}

export default Bio
